<template>
  <div>
    <b-card title="Панель управления 🚀">
      <b-card-text>Добро пожаловать</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
